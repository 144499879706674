<template>
  <div class="tw-bg-white tw-h-96 tw-items-center tw-flex tw-justify-center" style="max-height: 80vh;">
    <div class="tw-flex tw-justify-center tw-flex-col tw-gap-4">
      <div class="tw-flex tw-justify-center">
        <Loader class="main" customClass="big tw-flex tw-justify-center"/>
      </div>
      <div class="tw-text-xl tw-text-gray-500">Authenticating with <span class="tw-capitalize">{{$route.params.type}}</span>...</div>
  </div>
</div>
</template>

<script setup>

import {onMounted} from "vue";
import {useRoute} from "vue-router";
import Loader from '@/components/item-wizard/components/elements/Loader.vue'

const allowedSocialAuths = ['facebook', 'google']

const route = useRoute()

onMounted(async () => {
  let type = route.params.type
  let token = route.query.token

  if (!token || !allowedSocialAuths.includes(type) || route.params.tempId !== localStorage.getItem('tempId')) window.close()

  localStorage.setItem('social_auth_token',token)
  window.close()
})


</script>
